<template>
  <div class="blocks-youtube">
    <client-only>
      <div :id="playerId" class="__yt-player" />
      <div v-if="hidePlayButton === false" class="__player-controls">
        <img :src="playStatus === 'play' ? pauseButton : playButton" :alt="`${playStatus === 'play' ? 'Pause' : 'Play'} button`" data-not-lazy @click="togglePlayPause">
      </div>
    </client-only>
    <div v-if="videoLoadingState !== 'loaded'" class="__video-status-display">
      <div v-if="videoLoadingState === 'loading'" v-html="loadingIcon()" />
      <div v-else-if="videoLoadingState === 'error'" class="__error-text">
        Sorry, Unable to load the video!
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {Ref} from 'vue';
import playButton from '~/assets/images/icons/play-btn.webp';
import pauseButton from '~/assets/images/icons/pause-btn.webp';
import loadingIcon from '~/composables/loadingIcon';

// DATA
const player: Ref<any> = ref(null);
const isPlayerReady: Ref<boolean> = ref(false);
const playerId: Ref<string> = ref('player-' + Math.floor(Math.random() * 10000));  // Generate a unique id for each player
const playStatus: Ref<'play' | 'pause' | 'ended' | 'buffering' | 'unstarted' | 'unknow'> = ref('unstarted');
const videoLoadingState: Ref<'loading' | 'loaded' | 'error'> = ref('loading');

// PROPS
const props = defineProps({
  initIframe: {
    type: Boolean,
    default: true,
  },
  videoId: {
    type: String,
    required: true,
  },
  autoPlay: {
    type: Boolean,
    default: true,
  },
  playVideo: {
    type: Boolean,
    default: false,
  },
  videoUrl: {
    type: String,
    default: '',
  },
  hidePlayButton: {
    type: Boolean,
    default: false,
  },
});

// Emit Definition
const emit = defineEmits<{
  (event: 'videoStopped'): void;
}>();

defineExpose({
  pauseVideo,
  initIframe,
});

/**
 * Component MOUNTED!
 */
onMounted(() => {
  isPlayerReady.value = false;
  initIframe();
  runLoadUntilLoad();
});

/**
 * When playVideo prop changed
 */
watch(() => props.playVideo, () => {
  if (props.playVideo) {
    playVideo();
  } else {
    pauseVideo();
  }
});

/**
 * When initIframe prop changed
 */
watch(() => props.initIframe, () => {
  if (props.initIframe) {
    initIframe();
    runLoadUntilLoad();
  }
});

/**
 * When videoUrl prop changed
 */
watch(() => props.videoUrl, () => {
  if (props.videoUrl) {
    runLoadUntilLoad();
  }
});

/**
 * Init YouTube iframe
 */
function initIframe (): Promise<void> {
  return new Promise(async (resolve, reject) => {
    if (props.initIframe && props.videoUrl) {
      if (getVideoIdFromUrl() && window && (window as any).YTAPIReady) {
        await loadVideo();
      } else if (!document.getElementById('youtube-script')) {
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        tag.id = 'youtube-script';

        const firstScriptTag = document.getElementsByTagName('script')[0];
        if (firstScriptTag && firstScriptTag.parentNode) {
          firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        }

        (window as any).onYouTubeIframeAPIReady = () => {
          (window as any).YTAPIReady = true;  // A flag to check if API is ready
          resolve();
        };

        setTimeout(() => {
          initIframe().then(resolve, reject);
        }, 500);
      } else {
        setTimeout(() => {
          initIframe().then(resolve, reject);
        }, 500);
      }
    } else {
      resolve();
    }
  });
}

/**
 * Load YouTube video
 */
function loadVideo(attemps = 0): Promise<void> {
  return new Promise((resolve, reject) => {
    if (window && (window as any).YT && getVideoIdFromUrl() && (!player.value || !player.value.g)) {
      isPlayerReady.value = false;
      player.value = new (window as any).YT.Player(playerId.value, {
        height: '100%',
        width: '100%',
        videoId: getVideoIdFromUrl(),
        host: 'https://www.youtube.com',
        playerVars: {
          'playsinline': 1,
          'autoplay': props.autoPlay ? 1 : 0,
          'origin': window.location.origin,
          'controls': 0,
          'modestbranding': 1,
          'rel': 0,
          'showinfo': 0,
        },
        events: {
          'onReady': () => {
            onPlayerReady();
            resolve();
          },
          'onStateChange': onPlayerStateChange,
        },
      });
    } else {
      if (!window || !(window as any).YT) {
        initIframe();
      }
      if (attemps > 10) return;
      setTimeout(() => {
        loadVideo(++attemps).then(resolve, reject);
      }, 500);
    }
  });
}

/**
 * Fall back to load video if player is not ready
 */
function runLoadUntilLoad (attempt = 0) {
  if (videoLoadingState.value === 'error') return;
  if (props.initIframe && props.videoUrl && (!player.value || !player.value.g)) {
    initIframe();
    if (attempt < 10) {
      setTimeout(() => {
        runLoadUntilLoad(++attempt);
      }, 1000);
    } else {
      if (!player.value?.g) {
        videoLoadingState.value = 'error';
      } else {
        videoLoadingState.value = 'loaded';
      }
    }
  } else {
    videoLoadingState.value = 'loaded';
  }
}

/**
 * Get video id from url
 */
function getVideoIdFromUrl() {
  let videoId = null;

  if (!props.videoUrl) return null;

  // Handle full URLs (https://www.youtube.com/watch?v=VIDEO_ID)
  if (props.videoUrl.includes('youtube.com')) {
    const urlParams = new URLSearchParams(new URL(props.videoUrl).search);
    videoId = urlParams.get('v');
  }

  // Handle shortened URLs (https://youtu.be/VIDEO_ID)
  else if (props.videoUrl.includes('youtu.be')) {
    videoId = props.videoUrl.split('youtu.be/')[1];
    const ampersandPosition = videoId.indexOf('?');
    if (ampersandPosition !== -1) {
      videoId = videoId.substring(0, ampersandPosition);
    }
  }

  return videoId;
}

/**
 * When player is ready
 */
function onPlayerReady() {
  isPlayerReady.value = true;
}

/**
 * When player status changed
 */
async function onPlayerStateChange() {
  if (!player.value) {
    await initIframe();
  }
  const playerStatus = player.value.getPlayerState();
  switch (playerStatus) {
    case -1:
      playStatus.value = 'unstarted';
      break;
    case 0:
      playStatus.value = 'ended';
      break;
    case 1:
      playStatus.value = 'play';
      break;
    case 2:
      playStatus.value = 'pause';
      break;
    case 3:
      playStatus.value = 'buffering';
      break;
    default:
      playStatus.value = 'unknow';
      break;
  }
  if (playStatus.value === 'ended') {
    emit('videoStopped');
  }
}

/**
 * Toggle the play pause video
 */
function togglePlayPause () {
  if (playStatus.value === 'play') {
    pauseVideo();
  } else if (playStatus.value === 'pause') {
    playVideo();
  }
}

/**
 * Play video
 */
async function playVideo() {
  if (!player.value) {
    await initIframe();
  }
  if (player.value?.playVideo) {
    player.value.playVideo();
  }
}

/**
 * Pause video
 */
function pauseVideo() {
  if (typeof player.value?.pauseVideo === 'function') {
    player.value.pauseVideo();
  }
}
</script>

<style lang="scss">
.blocks-youtube {
  width: 100%;
  height: 100%;

  .__player-controls {
    position: absolute;
    width: 100%;
    height: 50px;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,0.5) 75%, rgba(0,0,0,0) 100%);
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 30px;
      height: auto;
    }
  }

  .__yt-player {
    z-index: 2;
  }

  .__video-status-display {
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100px;
    height: 50px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .__error-text {
      color: #fff;
      white-space: nowrap;
    }
  }
}
</style>
